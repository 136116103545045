import React from "react";
import { ChangeTypes } from "../../../data/enums";
import FormDropdown from "../../atoms/formDropdown";
import FormFieldQuestion from "../../atoms/formFieldQuestion";
import FormFieldRadioQuestion from "../../atoms/formFieldRadioQuestion";
import PageMessage from "../../atoms/pageMessage";
import {
  faBan,
  faBriefcaseMedical,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../atoms/button";
import Center from "../../atoms/center";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

const initialState = {
  ID: null,
  PartOfBody: 0,
  QMedicalTreatment: null,
  QClinicEmergency: null,
  QRefuseTreatment: null,
  QWitnesses: null,
  QWitnessesInfo: "",
  InjuryType: 0,
  QMechanismType: 0,
  QSeverityType: 0,
  open: false,
};

export default class TypeInjury extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    this.setState(this.props.state, () => {
      this.setState(
        {
          IncidentGUID: this.props.guid,
          IncidentNumber: this.props.number,
        },
        () => {
          this.props.update(this.state);
        }
      );
    });
  }

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "QSeverityType") {
      const severityLabel = this.props.dropdowns.find(
        (x) => x.value.toString() === value.toString()
      )?.label;
      if (
        severityLabel.startsWith("Low") ||
        severityLabel.startsWith("Medium")
      ) {
        this.toggle();
        this.setState({ QSeverityType: 0 });
        return;
      }
    }
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: value === "yes" }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value }, () => {
          this.props.update(this.state);
        });
        break;
    }
  };

  changeToFirstAid = () => {
    this.props.changeFirstAidType({ value: 6 });
  };

  toggle = () => this.setState({ open: !this.state.open });

  render() {
    const severityLabel = this.props.dropdowns.find(
      (x) => x.value.toString() === this.state.QSeverityType?.toString()
    )?.label;
    const isHighOrSevere =
      severityLabel?.startsWith("High") || severityLabel?.startsWith("Severe");
    return (
      <>
        <Modal
          size="lg"
          className="center-modal"
          isOpen={this.state.open}
          toggle={this.toggle}
        >
          <ModalHeader toggle={this.toggle}>
            <FontAwesomeIcon icon={faBriefcaseMedical}></FontAwesomeIcon>
            &nbsp;Incident Type Change
          </ModalHeader>
          <ModalBody>
            <PageMessage style={{ color: "#cd0000" }}>
              <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
              &nbsp;Incident Type Change
            </PageMessage>
            <p>
              Your incident falls into the First-Aid category based on the
              selected ‘Severity’ dropdown. Any injury or illness with a
              severity of Low or Medium should be classified as a First-Aid
              incident. Please adjust your ‘Incident Type’ to First-Aid to
              proceed with the submission.
            </p>
          </ModalBody>
          <ModalFooter>
            <Row style={{ width: "100%" }}>
              <Col sm="12" lg="6">
                <Center>
                  <Button
                    altColor={true}
                    style={{ padding: "5px 10px" }}
                    onClick={this.toggle}
                  >
                    <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                    &nbsp;Cancel
                  </Button>
                </Center>
              </Col>
              <Col sm="12" lg="6">
                <Center>
                  <Button
                    style={{ padding: "5px 10px" }}
                    onClick={this.changeToFirstAid}
                  >
                    <FontAwesomeIcon
                      icon={faBriefcaseMedical}
                    ></FontAwesomeIcon>
                    &nbsp;Change to First-Aid
                  </Button>
                </Center>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <FormDropdown
          value={this.state.QSeverityType}
          onChange={this.handleUserInput}
          name="QSeverityType"
          title="Injury / Illness Severity"
          stackLabel={true}
          filter="Injury/Illness"
          readOnly={this.props.readOnly}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 1005)}
          dropdownContentType="Severity"
        ></FormDropdown>
        {isHighOrSevere && (
          <PageMessage style={{ color: "#cd0000" }}>
            <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
            &nbsp;Executive leadership team will be notified immediately
          </PageMessage>
        )}

        <FormDropdown
          value={this.state.InjuryType}
          onChange={this.handleUserInput}
          name="InjuryType"
          title="Injury Type"
          stackLabel={true}
          filter="Injury/Illness"
          readOnly={this.props.readOnly}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 1)}
          dropdownContentType="Type of Injury"
        ></FormDropdown>
        <FormDropdown
          value={this.state.PartOfBody}
          onChange={this.handleUserInput}
          name="PartOfBody"
          title="What part of the body was affected?"
          stackLabel={true}
          filter="Injury/Illness"
          readOnly={this.props.readOnly}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 1001)}
          dropdownContentType="Part of Body"
        ></FormDropdown>
        <FormDropdown
          value={this.state.QMechanismType}
          onChange={this.handleUserInput}
          name="QMechanismType"
          title="What was the Injury/illness mechanism?"
          stackLabel={true}
          filter="Injury/Illness"
          readOnly={this.props.readOnly}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 1002)}
          dropdownContentType="Mechanism"
        ></FormDropdown>
        <FormFieldRadioQuestion
          title="Was medical treatment provided?"
          readOnly={this.props.readOnly}
          filter="Injury/Illness"
          name="QMedicalTreatment"
          required={true}
          value={this.state.QMedicalTreatment}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />

        <FormFieldRadioQuestion
          title="Was the injured worker taken to a Clinic/Emergency Room?"
          readOnly={this.props.readOnly}
          filter="Injury/Illness"
          name="QClinicEmergency"
          required={true}
          value={this.state.QClinicEmergency}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
        <FormFieldRadioQuestion
          title="Did the Employee refuse medical treatment or transport to the Emergency Room?"
          readOnly={this.props.readOnly}
          filter="Injury/Illness"
          name="QRefuseTreatment"
          required={true}
          value={this.state.QRefuseTreatment}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
        <FormFieldRadioQuestion
          title="Were there any witnesses?"
          readOnly={this.props.readOnly}
          filter="Injury/Illness"
          name="QWitnesses"
          required={true}
          value={this.state.QWitnesses}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
        {this.state.QWitnesses && (
          <FormFieldQuestion
            question="If yes, please provide witness contact information"
            readOnly={this.props.readOnly}
            value={this.state.QWitnessesInfo}
            onChange={(event) => this.handleUserInput(event, ChangeTypes.NOTES)}
            name="QWitnessesInfo"
            filter="Injury/Illness"
            required={true}
            maxLength={500}
          ></FormFieldQuestion>
        )}
      </>
    );
  }
}
